import { authAtom } from '@/lib/use-auth.state';
import { AnalysisResponse, DDQPairWithEditHistory, DDQPairWithMeta, SimilarPair } from '@/types';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  atomWithQuery,
  atomWithMutation,
  queryClientAtom
} from 'jotai-tanstack-query';
import { addReplacementTextAtom } from './use-editor-state';
import { progressiveSearchResultAtom, searchBiasAtom } from './progressive-search';
import dayjs from 'dayjs';
import { filterOnOffAtom, filterResults, selectedCategoryAtom, selectedContentRangeDateAtom } from './use-filter-state';

type DDQAnalysisState = {
  analysisResponse: AnalysisResponse | null;
  selectedQuestion: string | null;
};

export const ddqAnalysisAtom = atom<DDQAnalysisState>({
  analysisResponse: null,
  selectedQuestion: null
});

type QuestionState = {
  content: string;
  selectedAnswer: string | null;
  savesFromSearch: DDQPairWithMeta[];
  generationInstructions: string;
  selectedAnswersForGeneration: string[];
  generationResult: string | undefined;
};

type QuestionDict = { [questionId: string]: QuestionState };

export const workingQuestionAtom = atom<QuestionDict>({});

const setDDQAnalysisAtom = atom(
  null,
  (get, set, analysis: AnalysisResponse) => {
    const isCurrentAnalysis =
      get(ddqAnalysisAtom).analysisResponse?.id === analysis.id;

    if (!isCurrentAnalysis) {
      set(ddqAnalysisAtom, {
        analysisResponse: analysis,
        selectedQuestion: null
      });

      set(setSelectedQuestionAtom, analysis.similar_pairs[0][0].id);
    }
  }
);

const getDDQAtom = atom((get) => {
  const { analysisResponse } = get(ddqAnalysisAtom);

  if (analysisResponse) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { similar_pairs, ...rest } = analysisResponse;
    return rest;
  }
  return null;
})

const setSelectedQuestionAtom = atom(null, (get, set, id: string | null) => {
  const ddqAnalysisState = get(ddqAnalysisAtom);

  set(ddqAnalysisAtom, { ...ddqAnalysisState, selectedQuestion: id });

  const workingQuestionState = get(workingQuestionAtom);
  const currentWorkingQuestion = workingQuestionState[id || ''];

  // Cover the edge case that data hasn't yet been set, or only the selected answers for generation have been set.
  const questionNeedsToBeInitialized =
    id !== null &&
    (currentWorkingQuestion === undefined ||
      (Object.keys(currentWorkingQuestion).length === 1 &&
        Object.keys(currentWorkingQuestion).includes(
          'selectedAnswersForGeneration'
        )));

  if (questionNeedsToBeInitialized) {
    // init with the first selected answer
    const pairObj = get(pairObjAtom);

    const startingAnswer =
      pairObj && pairObj[1].length > 0 ? pairObj[1][0].pair.id : null;

    set(workingQuestionAtom, {
      ...workingQuestionState,
      [id]: {
        content: (pairObj && pairObj[0].text) || '',
        selectedAnswer: startingAnswer,
        savesFromSearch: [],
        generationInstructions: '',
        // By default init the selected answers with the ones that were selected as highly-relevant in the analysis
        selectedAnswersForGeneration: currentWorkingQuestion ? [...currentWorkingQuestion.selectedAnswersForGeneration] : ((pairObj && pairObj[1]) || []).filter(v => v.is_relevant === 'high').map(v => v.pair.id),
        generationResult: undefined
      }
    });
  }

  set(searchBiasAtom, id || '')

  console.log('selected question state', get(workingQuestionAtom)[id || '']);
});

const updateQuestionAtom = atom(
  null,
  (get, set, id: string, data: Partial<QuestionState>) => {
    set(workingQuestionAtom, {
      ...get(workingQuestionAtom),
      [id]: { ...get(workingQuestionAtom)[id], ...data }
    });
  }
);

export const getCurrentQuestionDataAtom = atom((get) => {
  const selectedQuestion = get(ddqAnalysisAtom).selectedQuestion;

  if (!selectedQuestion) return;

  const questionData = get(workingQuestionAtom);

  if (selectedQuestion in questionData) return questionData[selectedQuestion];
});

const setCurrentSelectedAnswerAtom = atom(null, (get, set, id: string) => {
  const questionObj = get(questionObjAtom);

  if (!questionObj) return;

  set(updateQuestionAtom, questionObj.id, { selectedAnswer: id });
});

export const addPairToSavesFromSearchAtom = atom(null, (get, set, pair: DDQPairWithMeta) => {
  const questionObj = get(questionObjAtom);

  if (!questionObj) return;

  const currentQuestionData = get(getCurrentQuestionDataAtom);

  if (currentQuestionData && !currentQuestionData.savesFromSearch.some(p => p.pair.id === pair.pair.id)) {
    set(updateQuestionAtom, questionObj.id, {
      savesFromSearch: [...currentQuestionData.savesFromSearch, pair]
    });
  }

  set(addSelectedPairForGenerationAtom, pair.pair.id);
});

export const removePairFromSavesFromSearchAtom = atom(null, (get, set, pair: DDQPairWithMeta) => {
  const questionObj = get(questionObjAtom);

  if (!questionObj) return;

  const currentQuestionData = get(getCurrentQuestionDataAtom);

  if (currentQuestionData && currentQuestionData.savesFromSearch.some(p => p.pair.id === pair.pair.id)) {
    set(updateQuestionAtom, questionObj.id, {
      savesFromSearch: currentQuestionData.savesFromSearch.filter(p => p.pair.id !== pair.pair.id)
    });
  }

  set(removeSelectedPairForGenerationAtom, pair.pair.id);
});

export const addSelectedPairForGenerationAtom = atom(
  null,
  (get, set, pairId: string) => {
    const questionObj = get(questionObjAtom);

    if (!questionObj) return;

    const currentQuestionData = get(getCurrentQuestionDataAtom);

    if (
      currentQuestionData &&
      !currentQuestionData.selectedAnswersForGeneration.includes(pairId)
    ) {
      set(updateQuestionAtom, questionObj.id, {
        selectedAnswersForGeneration: [...currentQuestionData.selectedAnswersForGeneration, pairId]
      });
    }
  }
);

export const removeSelectedPairForGenerationAtom = atom(
  null,
  (get, set, pairId: string) => {
    const questionObj = get(questionObjAtom);

    if (!questionObj) return;

    const currentQuestionData = get(getCurrentQuestionDataAtom);

    if (
      currentQuestionData &&
      currentQuestionData.selectedAnswersForGeneration.includes(pairId)
    ) {
      const index = currentQuestionData.selectedAnswersForGeneration.indexOf(pairId);

      set(updateQuestionAtom, questionObj.id, {
        selectedAnswersForGeneration: [...currentQuestionData.selectedAnswersForGeneration.slice(0, index), ...currentQuestionData.selectedAnswersForGeneration.slice(index + 1)]
      });
    }
  }
);

export const resetSelectedPairsForGenerationAtom = atom(null,
  (_get, set, questionObjId: string, pairIds: string[]) => {
    set(updateQuestionAtom, questionObjId, {
      selectedAnswersForGeneration: [...pairIds]
    })
  }
)

const generationInstructionsAtom = atom((get) => {
  const currentQuestionData = get(getCurrentQuestionDataAtom);

  return currentQuestionData?.generationInstructions;
}, (get, set, instructions: string) => {
  const questionObj = get(questionObjAtom);

  if (!questionObj) return;

  set(updateQuestionAtom, questionObj.id, {
    generationInstructions: instructions
  });
});

const generationResultAtom = atom(
  (get) => {
    const currentQuestionData = get(getCurrentQuestionDataAtom);

    return currentQuestionData?.generationResult;
  },
  (get, set, result: string) => {
    const questionObj = get(questionObjAtom);

    if (!questionObj) return;

    set(updateQuestionAtom, questionObj.id, {
      generationResult: result
    });
  }
);

const pairObjAtom = atom<SimilarPair | null>((get) => {
  const config = get(ddqAnalysisAtom);
  const foundQuestion = config.analysisResponse?.similar_pairs.find(([q]) => {
    return q.id === config.selectedQuestion;
  });

  if (!foundQuestion) return null;

  const results = get(progressiveSearchResultAtom);

  let responsesFromSearch = results[foundQuestion[0].id]?.response || [];
  if (responsesFromSearch.length === 0) responsesFromSearch = foundQuestion[1].length ? foundQuestion[1] : [];

  const filterOnOff = get(filterOnOffAtom);
  const selectedCategory = get(selectedCategoryAtom);
  const selectedContentRangeDate = get(selectedContentRangeDateAtom);

  const answers = filterResults([...responsesFromSearch], { filterOnOff, selectedCategory, selectedContentRangeDate }).sort((a, b) => {
      const order = ['high', 'low', false];
      
      if (a.is_relevant === b.is_relevant) {
          return dayjs(b.pair.ddq.approved_date).diff(dayjs(a.pair.ddq.approved_date));
      }
      
      return order.indexOf(a.is_relevant) - order.indexOf(b.is_relevant);
  });

  return [foundQuestion[0], answers];
});

export const questionObjAtom = atom((get) => {
  const pair = get(pairObjAtom);

  if (pair) return pair[0];
  else return null;
});

const answerObjAtom = atom((get) => {
  const pair = get(pairObjAtom);

  if (!pair) return null;

  const currentQuestionData = get(getCurrentQuestionDataAtom);

  const foundAnswer = pair[1].find((a) => {
    return a.pair.id === currentQuestionData?.selectedAnswer;
  }) || (pair[1].length > 0 ? pair[1][0] : undefined);

  if (foundAnswer) return foundAnswer;
  else return null;
});

const pairPaginationAtom = atom((get) => {
  const currentList = get(ddqAnalysisAtom).analysisResponse?.similar_pairs;

  const pairObj = get(pairObjAtom);

  if (pairObj === null || !currentList) {
    return {
      current: undefined,
      next: undefined,
      prev: undefined,
      length: 0
    };
  }

  const current = Math.max(currentList.findIndex((value) => {
    return value[0].id === pairObj[0].id;
  }), 0);

  const length = currentList.length;
  const prev = current > 0 ? currentList[current - 1][0].id : undefined;
  const next =
    current < length - 1 ? currentList[current + 1][0].id : undefined;

  return {
    current,
    next,
    prev,
    length
  };
});

const answerPaginationAtom = atom((get) => {
  const pairObj = get(pairObjAtom);

  const currentList = pairObj && pairObj[1] || [];

  if (pairObj === null || !currentList) {
    return {
      current: undefined,
      next: undefined,
      prev: undefined,
      length: 0
    };
  }

  const answerObj = get(answerObjAtom);

  const current = answerObj && currentList.indexOf(answerObj);

  if (current === null) {
    return {
      current: undefined,
      next: undefined,
      prev: undefined,
      length: 0
    };
  }

  const length = currentList.length;
  const prev = current > 0 ? currentList[current - 1].pair.id : undefined;
  const next =
    current < length - 1 ? currentList[current + 1].pair.id : undefined;

  return {
    current,
    next,
    prev,
    length
  };
});

const currentEditStateQueryAtom = atomWithQuery((get) => {
  const questionObj = get(questionObjAtom);
  const accessToken = get(authAtom);

  return {
    queryKey: ['ddq_pair_history', questionObj?.id],
    queryFn: async ({ queryKey: [, id] }) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/pair/${id}/history`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      const data = await response.json();

      return data as DDQPairWithEditHistory;
    }
  };
});

const currentEditStateAtom = atom((get) => {
  const questionObj = get(questionObjAtom);

  if (!questionObj) return;

  const query = get(currentEditStateQueryAtom);

  const { data } = query;

  if (data) return data;

  return questionObj;
});

const currentEditStateEditMutationAtom = atomWithMutation((get) => {
  const accessToken = get(authAtom);
  const queryClient = get(queryClientAtom);

  return {
    mutationFn: async ({ content, id }: { content: string; id: string }) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/pair/${id}/edit`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ content })
        }
      );
      const data = await response.json();

      return data as DDQPairWithEditHistory;
    },
    onSuccess(
      data: DDQPairWithEditHistory,
      variables: { content: string; id: string }
    ) {
      queryClient.setQueryData(['ddq_pair_history', variables.id], data);
    }
  };
});

export const currentEditStateCopyMutationAtom = atomWithMutation((get) => {
  const accessToken = get(authAtom);
  const queryClient = get(queryClientAtom);

  return {
    mutationFn: async ({
      copyPairId,
      id
    }: {
      copyPairId: string;
      id: string;
    }) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/pair/${id}/copy`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ copy_pair_id: copyPairId })
        }
      );
      const data = await response.json();

      return data as DDQPairWithEditHistory;
    },
    onSuccess(
      data: DDQPairWithEditHistory,
      variables: { copyPairId: string; id: string }
    ) {
      queryClient.setQueryData(['ddq_pair_history', variables.id], data);
    }
  };
});

const currentEditStateGenerateMutationAtom = atomWithMutation((get) => {
  const accessToken = get(authAtom);
  const queryClient = get(queryClientAtom);

  return {
    mutationFn: async ({
      sourcePairIds,
      content,
      id
    }: {
      sourcePairIds: string[];
      content: string;
      id: string;
    }) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/pair/${id}/save-generation`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ source_pair_ids: sourcePairIds, content })
        }
      );
      const data = await response.json();

      return data as DDQPairWithEditHistory;
    },
    onSuccess(
      data: DDQPairWithEditHistory,
      variables: { sourcePairIds: string[]; content: string; id: string }
    ) {
      queryClient.setQueryData(['ddq_pair_history', variables.id], data);
    }
  };
});

const editorMutationLoadingAtom = atom((get) => {
  const mutateCurrentEditStateWithEdit = get(currentEditStateEditMutationAtom);
  const mutateCurrentEditStateWithCopy = get(currentEditStateCopyMutationAtom);
  const mutateCurrentEditStateWithGeneration = get(
    currentEditStateGenerateMutationAtom
  );

  const questionObj = get(questionObjAtom);

  return (
    (mutateCurrentEditStateWithEdit.isPending &&
      mutateCurrentEditStateWithEdit.variables.id === questionObj?.id) ||
    (mutateCurrentEditStateWithCopy.isPending &&
      mutateCurrentEditStateWithCopy.variables.id === questionObj?.id) ||
    (mutateCurrentEditStateWithGeneration.isPending &&
      mutateCurrentEditStateWithGeneration.variables.id === questionObj?.id)
  );
});

const isCurrentAnswerCopyingAtom = atom((get) => {
  const mutateCurrentEditStateWithCopy = get(currentEditStateCopyMutationAtom);
  const answerObj = get(answerObjAtom);
  const questionObj = get(questionObjAtom);

  if (!answerObj || !questionObj) return false;

  return (
    mutateCurrentEditStateWithCopy.isPending &&
    mutateCurrentEditStateWithCopy.variables.id === questionObj.id &&
    mutateCurrentEditStateWithCopy.variables.copyPairId === answerObj.pair.id
  );
});

const isCurrentAnswerGenerationSavingAtom = atom((get) => {
  const mutateCurrentEditStateWithGeneration = get(
    currentEditStateGenerateMutationAtom
  );
  const questionObj = get(questionObjAtom);

  if (!questionObj) return false;

  return (
    mutateCurrentEditStateWithGeneration.isPending &&
    mutateCurrentEditStateWithGeneration.variables.id === questionObj.id
  );
});

const setReplacementTextConditionallyAtom = atom(
  null,
  (get, set, { id, content }) => {
    const questionObj = get(questionObjAtom);
    if (questionObj && questionObj.id === id) {
      set(addReplacementTextAtom, content);
    }
  }
);

/**
 * The purpose of this is to track the current ddq, and selected question and answer state
 * @returns
 */
export function useDDQState() {
  const state = useAtom(ddqAnalysisAtom);

  const ddqObj = useAtomValue(getDDQAtom);

  const pairObj = useAtomValue(pairObjAtom);

  const questionObj = useAtomValue(questionObjAtom);

  const answerObj = useAtomValue(answerObjAtom);

  const setSelectedQuestion = useSetAtom(setSelectedQuestionAtom);

  const setSelectedAnswer = useSetAtom(setCurrentSelectedAnswerAtom);

  const addPairToSavesFromSearch = useSetAtom(addPairToSavesFromSearchAtom);

  const removePairFromSavesFromSearch = useSetAtom(removePairFromSavesFromSearchAtom);

  const updateQuestionData = useSetAtom(updateQuestionAtom);

  const currentQuestionData = useAtomValue(getCurrentQuestionDataAtom);

  const setDDQAnalysis = useSetAtom(setDDQAnalysisAtom);

  const pairPagination = useAtomValue(pairPaginationAtom);

  const answerPagination = useAtomValue(answerPaginationAtom);

  const currentEditState = useAtomValue(currentEditStateAtom);

  const mutateCurrentEditStateWithEdit = useAtomValue(
    currentEditStateEditMutationAtom
  );

  const mutateCurrentEditStateWithCopy = useAtomValue(
    currentEditStateCopyMutationAtom
  );

  const mutateCurrentEditStateWithGeneration = useAtomValue(
    currentEditStateGenerateMutationAtom
  );

  const isAnyEditorMutationLoading = useAtomValue(editorMutationLoadingAtom);

  const isCurrentAnswerCopying = useAtomValue(isCurrentAnswerCopyingAtom);

  const isCurrentAnswerSavingGeneration = useAtomValue(
    isCurrentAnswerGenerationSavingAtom
  );

  const setReplacementTextConditionally = useSetAtom(setReplacementTextConditionallyAtom);

  const addSelectedPairForGeneration = useSetAtom(addSelectedPairForGenerationAtom);

  const removeSelectedPairForGeneration = useSetAtom(removeSelectedPairForGenerationAtom);

  const resetSelectedPairsForGeneration = useSetAtom(resetSelectedPairsForGenerationAtom);

  const [generationInstructions, setGenerationInstructions] = useAtom(generationInstructionsAtom);

  const [generationResult, setGenerationResult] = useAtom(generationResultAtom);

  return {
    state,
    ddqObj,
    pairObj,
    questionObj,
    answerObj,
    setSelectedQuestion,
    setSelectedAnswer,
    addPairToSavesFromSearch,
    removePairFromSavesFromSearch,
    updateQuestionData,
    currentQuestionData,
    setDDQAnalysis,
    pairPagination,
    answerPagination,
    currentEditState,
    mutateCurrentEditStateWithEdit,
    mutateCurrentEditStateWithCopy,
    mutateCurrentEditStateWithGeneration,
    isAnyEditorMutationLoading,
    isCurrentAnswerCopying,
    isCurrentAnswerSavingGeneration,
    setReplacementTextConditionally,
    addSelectedPairForGeneration,
    removeSelectedPairForGeneration,
    resetSelectedPairsForGeneration,
    generationInstructions, 
    setGenerationInstructions,
    generationResult,
    setGenerationResult
  };
}
