import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';

const lowRelevancyVisibilityAtom = atomWithStorage<boolean>('lowRelevancyVisibility', false);

export function useGenerationState() {
  const [lowRelevancyVisibility, setLowRelevancyVisibility] = useAtom(lowRelevancyVisibilityAtom);

  return {
    lowRelevancyVisibility,
    setLowRelevancyVisibility
  };
}
