import React from 'react';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { BarChartIcon, MagicWandIcon, ReaderIcon, ClockIcon } from '@radix-ui/react-icons';
import dayjs from 'dayjs';
import { DDQPairWithMeta } from '@/types';
import CategoryTag from '@/components/generic/category-tag';

type ContentLabelProps = {
  data: DDQPairWithMeta;
  contextCategoryId?: string;
};

const ContentLabel: React.FC<ContentLabelProps> = ({ data, contextCategoryId }) => {
  return (
    <div className='flex items-center gap-1 w-full bg-card border rounded-lg py-1 px-2 shadow'>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className={`flex gap-1 items-center ${data.is_relevant === 'high' ? 'text-green-500' : 'text-gray-500'}`}>
              <BarChartIcon />
              {data.is_relevant === 'high' ? 'High' : 'Low'}
            </div>
          </TooltipTrigger>
          <TooltipContent side='top'>
            <p>{data.is_relevant === 'high' ? 'Highly-relevant search result' : 'Low-relevancy search result'}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div className='text-gray-300'>|</div>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='flex gap-1 items-center italic'>
              <MagicWandIcon />
              {data.relevancy_description}
            </div>
          </TooltipTrigger>
          <TooltipContent side='top'>
            <p>This result is relevant because "{data.relevancy_description}"</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div className='text-gray-300'>|</div>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='flex gap-1 items-center bold'>
              <ReaderIcon />
              {data.pair.ddq.friendly_name}
            </div>
          </TooltipTrigger>
          <TooltipContent side='top'>
            <p>The original file: "{data.pair.ddq.upload_name.replace(/\.[^/.]+$/, '')}"</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div className='text-gray-300'>|</div>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className='flex gap-1 items-center'>
              <ClockIcon />
              {dayjs(data.pair.ddq.approved_date).fromNow()}
            </div>
          </TooltipTrigger>
          <TooltipContent side='top'>
            <p>
              Content approved on{' '}
              {dayjs(data.pair.ddq.approved_date).format('MMMM D, YYYY')}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <div className='text-gray-300'>|</div>
      <CategoryTag
        categoryId={data.pair.ddq.category_id}
        contextCategoryId={contextCategoryId}
      />
    </div>
  );
};

const CompressedContentLabel: React.FC<ContentLabelProps> = ({ data, contextCategoryId }) => {
  return (
    <div className='flex flex-col gap-1 w-full'>
      <div className='flex items-center gap-1'>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className={`flex gap-1 items-center ${
                  data.is_relevant === 'high'
                    ? 'text-green-500'
                    : 'text-gray-500'
                }`}
              >
                <BarChartIcon className='flex-shrink-0' />
                {data.is_relevant === 'high' ? 'High' : 'Low'}
              </div>
            </TooltipTrigger>
            <TooltipContent side='top'>
              <p>
                {data.is_relevant === 'high'
                  ? 'Highly-relevant search result'
                  : 'Low-relevancy search result'}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <div className='text-gray-300'>|</div>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className='flex gap-1 items-center italic truncate'>
                <MagicWandIcon className='flex-shrink-0' />
                {data.relevancy_description}
              </div>
            </TooltipTrigger>
            <TooltipContent side='top'>
              <p>
                This result is relevant because "{data.relevancy_description}"
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className='flex items-center gap-1'>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className='flex gap-1 items-center bold truncate'>
                <ReaderIcon className='flex-shrink-0' />
                {data.pair.ddq.friendly_name}
              </div>
            </TooltipTrigger>
            <TooltipContent side='top'>
              <p>
                The original file: "
                {data.pair.ddq.upload_name.replace(/\.[^/.]+$/, '')}"
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <div className='text-gray-300'>|</div>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className='flex gap-1 items-center truncate'>
                <ClockIcon className='flex-shrink-0' />
                {dayjs(data.pair.ddq.approved_date).fromNow()}
              </div>
            </TooltipTrigger>
            <TooltipContent side='top'>
              <p>
                Content approved on{' '}
                {dayjs(data.pair.ddq.approved_date).format('MMMM D, YYYY')}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className='flex justify-start'>
        <CategoryTag
          categoryId={data.pair.ddq.category_id}
          contextCategoryId={contextCategoryId}
        />
      </div>
    </div>
  );
};

export default ContentLabel;

export { ContentLabel, CompressedContentLabel };
